<template>
    <div class="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-middle uk-flex-center uk-flex-column">
        <img 
            :src="`${images}/not-found.svg`" 
            alt="Not found image"
            loading="lazy" 
        />
        <div class="uk-width-1-1 uk-margin-top">
            <p class="uk-margin-remove uk-text-center myrobin-text-not-found">No results found!</p>
            <p class="uk-margin-remove uk-text-center myrobin-text-not-found">Sorry, there is no data to be shown with your filter settings.</p>
            <p class="uk-text-center myrobin-text-not-found">Please reorganize your filter settings now!</p>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    name: 'NotFoundFilter',
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
};
</script>

<style scoped>
.myrobin-text-not-found {
    font-size: 20px;
    font-style: italic;
    color: #A7A7A7;
    font-weight: 400;
}
</style>
